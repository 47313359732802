import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="form--tags"
export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      create: true,
      sortField: 'text',
      searchField: 'search'
    })
  }

  disconnect() {
    this.element.tomselect?.destroy()
  }
}
